import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ReactGA from "react-ga";
import md5 from 'md5';
import sha1 from 'sha1';
import sha256 from 'sha256';
import sha512 from 'sha512';
import { Link } from 'gatsby';
import DonationFooter from "../../components/donation-footer";
import AdBanner from "../../components/ad-banner";

class View extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hashType: 'MD5',
            count: 10
        }

    }

    componentDidMount() {
        ReactGA.initialize("UA-115464417-1");
        if (!(window.location.hostname === "localhost")) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }

    handleChange = (event) => {

        if (event.target.id === 'hash-type'){

            this.setState({
                hashType: event.target.value
            }, () => {
                console.log(this.state.hashType);
            });
            
        } else if (event.target.id === 'count') {
            
            this.setState({
                count: parseInt(event.target.value)
            }, () => {
                console.log(this.state.count);
            });
            
        }

    }

    render() {
        return (
            <Layout>
                <SEO title="Generate Random Hashes" keywords={[`generate`, `random`, `hashes`]} />
                {/* <Navigation /> */}
                {/* <AdBanner></AdBanner> */}
                <Container>
					<Row style={{ display: "flex", justifyContent: "center" }}>
                        <Col>
                            <h3
                                style={{
                                    textAlign: "center",
                                    fontWeight: "300"
                                }}
                            >
                                <Link to="/products">{"⬅ Generate Random Hashes"}</Link>
                            </h3>
                        </Col>
					</Row>
				</Container>
                <Container>
                    <Row className="card-2" style={{padding: '1rem'}}>
                        <Col>
                            <Row>
                                <Col>
                                    {/* Select Hash */}
                                    <Form.Group>
                                        <Form.Label>Select Hash Function</Form.Label>
                                        <Form.Control id="hash-type" as="select" size="sm" onChange={this.handleChange}>
                                            <option>MD5</option>
                                            <option>SHA1</option>
                                            <option>SHA256</option>
                                            <option>SHA512</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    {/* Select Select Count */}
                                    <Form.Group>
                                        <Form.Label>Select Count</Form.Label>
                                        <Form.Control id="count" as="select" size="sm" onChange={this.handleChange}>
                                            <option>10</option>
                                            <option>20</option>
                                            <option>30</option>
                                            <option>40</option>
                                            <option>50</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div style={{ marginTop: 20 }} />
                <Container>
                    <Row className="card-2" style={{padding: '1rem'}}>
                        <Col>
                            {(new Array(this.state.count).fill(0)).map((value, index) =>
                                <Row key={index}><p style={{wordBreak:'break-all'}}>
                                    
                                    {(this.state.hashType === 'MD5') ? md5(Math.random().toString()) : null }

                                    {(this.state.hashType === 'SHA1') ? sha1(Math.random().toString()) : null }

                                    {(this.state.hashType === 'SHA256') ? sha256(Math.random().toString()) : null }

                                    {(this.state.hashType === 'SHA512') ? sha512(Math.random().toString()) : null }

                                </p></Row>
                            )}
                        </Col>
                    </Row>
                </Container>

                <div style={{ marginTop: 50 }} />
                <Container>
                    <DonationFooter ad={false} />
                </Container>
                                
            </Layout>
        );
    }
}

export default View;
